.footer{
width: 100%;
height: 45vh;
background-color: #020830;
color: #FFFFFF;
padding: 40px 50px;

display: flex;
flex-direction: column;
}

.footer .row1{ 
    margin-bottom: 20px;
}

.footer .row1 p{
font-size: 50px;
font-weight: 400;
line-height: 75px;
letter-spacing: 0em;

}

.footer .row2{
    display: flex;
 
}

.footer .row2 .box{ 
    width: 15%;
}



.footer .row2 .box ul{  
    display: flex;
    flex-direction: column;
    gap: 0px;
}


.footer .row2 .box ul li{  
font-size: 14px;
font-weight: 300;
line-height: 21px;
letter-spacing: 0em;
color: #FFFFFF;
}


.footer .row2 .last-box{
    position: relative;
  right: -310px;
  top: 120px;
}


.footer .row2 .last-box p{
  
font-size: 14px;
font-weight: 300;

letter-spacing: 0em;


}
/* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .footer {
        padding: 30px;
        height: auto;
    }
    .footer .row1 p {
        font-size: 40px;
    }
    .footer .row2 {
        display: flex;
        flex-wrap: wrap;
    }
    .footer .row2 .box {
        width: 50%;
        margin-bottom: 20px;
      }
  }
  
  /* Small Devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .footer .row2 {
        display: flex;
        flex-wrap: wrap;
      }

      .footer .row2 .box {
        width: 33%;
        margin-bottom: 20px;
      }
  }
  
  /* Medium Devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .footer .row2 .box {
        width: 25%;
      }
  }
  
  /* Large Devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    .footer .row2 .box {
        width: 25%;
      }
  }
  
  /* Extra Large Devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1201px) {
    .footer .row2 .box {
        width: 25%;
      }
  }
  