.main-section-explore{
    position: relative;
  width: 100%;
  height: 80vh;
  /* background-color: aquamarine; */
  background: url('../assets/images/explore.png') center/cover no-repeat; 
}

.explore-mid-text {
    position: absolute;
    width: 50%;
    top: 50%;
    left: 68px;
    transform: translate(0%, -50%);
  }
  
  .explore-mid-text .box1 {
    position: relative;
  }
  
  .explore-mid-text .box1 .main-heading-text {
    color: #000000;
    font-size: 50px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: left;
    
  }

  .explore-mid-text .box1 .main-heading-text  span{

    color: #556FE2;
  }
  
  .explore-mid-text .box1 .vector {
    position: absolute;
    top: -13px;
  right: 137px;
  width: 40px;
  height: 40px;
  }
  
  .explore-mid-text .box3 {
    display: flex;
    margin-top: 20px;
  }
  
  .explore-mid-text .box3 .search-label {
    position: relative;
    width: 30%;
  }
  
  .explore-mid-text .box3 .search-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .explore-mid-text .box3 .search-input {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0em;
    padding: 18px 10px 18px 35px; /* Adjust the padding to accommodate the icon */
    width: 100%;
    color: #000000; /* Change the input text color */
    border: 1px solid #000000; /* Change the border color */
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    margin-left: 10px; /* Add margin between icon and text */
  }
  
  .explore-mid-text .box3 .search-input::placeholder {
    font-size: 17px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }


  
  .explore-mid-text .box3 .search-input:focus {
    outline: none; /* Optional: Remove the default focus outline */
    border-color: #3498db; /* Optional: Change the border color on focus */
  }
  


  .explore-end-text {
    width: 100%;
    position: absolute;
    bottom: 3%;
   display: flex;
   justify-content: space-between;
   padding: 0px 32px;
  }
  
  .explore-end-text p {
    font-size: 35px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    
    color: #0b0b0b;
  }
  


  .explore-end-text  .featured-btn {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0em;
    padding: 10px;
    width: 18%;
    background-color: #556FE2;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust spacing between text and icon */
  }
  
  .explore-end-text  .featured-btn .arrow-icon {
    margin-left: 10px; /* Adjust the space between text and icon */
  }










  .explore-categories{
    display: flex;
    width: 100%;
    background-color: #000000;
    border: 1px solid #000000;
  }

  .explore-categories .box{
    width: 12.5%;
    height: 205px;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 10%;
    display: flex;
    padding: 0px 10px; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  
  .explore-categories .box .box-img{
    width: 54px;
    height: 54px;
    margin-bottom: 15px;
  }
  .explore-categories .box .box-text{

    font-size: 18px;
    font-weight: 275;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    
  }










/* .explore-category-detail{
    width: 100%;
    padding: 40px;
    background-color: #DCDCDC;
   
}


.explore-category-detail .detail-box{
    width: 388px;
    height: 479px;
    background-color: #ffffff;
    padding: 23px;
    border-radius: 5%;
    border: 1px solid #DCDCDC;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
} */
.explore-category-detail {
    width: 100%;
    padding: 60px;
    background-color: #dcdcdc29;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(388px, 1fr)); /* 3 boxes in a row with a minimum width of 300px */
    gap: 90px; /* Spacing between boxes */
  }
  
  .explore-category-detail .detail-box {
    background-color: #ffffff;
    padding: 23px;
    border-radius: 5%;
    border: 1px solid #dcdcdc;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  

.explore-category-detail .detail-box img{
    width: 100%;
    height: 272px;
}


.explore-category-detail .detail-box .bottom-detail{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}



.explore-category-detail .detail-box .bottom-detail .bottom-head{ 
font-size: 22px;
font-weight: 400;
line-height: 33px;
letter-spacing: 0em;


}
 


.explore-category-detail .detail-box .bottom-detail .bottom-end-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.explore-category-detail .detail-box .bottom-detail .bottom-end-line .large-size{
    color: #556FE2;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;

    
}


.explore-category-detail .detail-box .bottom-detail .bottom-end-line .small-size{
color: #000000;
font-size: 15px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;


}












/* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-section-explore {
    padding: 20px;
  }
  .explore-mid-text {
    width: 100%;
    left: 0px;
    padding: 20px;
  }
  .explore-mid-text .box3 {
    width: 100%;
  }
  .explore-mid-text .box3 .search-label {
    width: 75%;
  }

  .explore-end-text {
    padding: 0px 20px;
    justify-content: space-between;
    left: 0px;
  }

  .explore-end-text .featured-btn {
    width: auto;
  }

  .explore-end-text p {
    font-size: 24px;
  }

  .explore-category-detail {
    padding: 25px;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .explore-categories {
    flex-wrap: wrap;
  }

  .explore-categories .box {
    width: 50%;
  }
  .explore-categories .box-text {
    
  }
  /* .explore-categories .other-gaming{
    width: 100% !important;
  } */


  .explore-mid-text .box1 .main-heading-text {
    font-size: 40px;
    line-height: 60px;
  }
}

/* Small Devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .main-section-explore {
    padding: 20px;
  }
  .explore-mid-text {
    width: 100%;
    left: 0px;
    padding: 20px;
  }
  .explore-mid-text .box3 {
    width: 100%;
  }
  .explore-mid-text .box3 .search-label {
    width: 50%;
  }

  .explore-end-text {
    padding: 0px 20px;
    justify-content: space-between;
    left: 0px;
  }

  .explore-end-text .featured-btn {
    width: auto;
  }

  .explore-end-text p {
    font-size: 24px;
  }

  .explore-category-detail {
    padding: 10px;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  }

  .explore-categories {
    flex-wrap: wrap;
  }

  .explore-categories .box {
    width: 25%;
  }
  /* .explore-categories .other-gaming{
    width: 50% !important;
  } */


  .explore-mid-text .box1 .main-heading-text {
    font-size: 40px;
    line-height: 60px;
  }
}

/* Medium Devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .main-section-explore {
    padding: 20px;
  }
  .explore-mid-text {
    width: 100%;
    left: 0px;
    padding: 20px;
  }
  .explore-mid-text .box3 {
    width: 100%;
  }
  .explore-mid-text .box3 .search-label {
    width: 50%;
  }

  .explore-end-text {
    padding: 0px 20px;
    justify-content: space-between;
    left: 0px;
  }

  .explore-end-text .featured-btn {
    width: auto;
  }

  .explore-end-text p {
    font-size: 24px;
  }

  .explore-category-detail {
    padding: 10px;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  }

  .explore-categories {
    flex-wrap: wrap;
  }

  .explore-categories .box {
    width: 25%;
  }
  /* .explore-categories .other-gaming{
    width: 50% !important;
  } */


  .explore-mid-text .box1 .main-heading-text {
    font-size: 45px;
    line-height: 70px;
  }
}

/* Large Devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .explore-mid-text .box3 .search-label {
    position: relative;
    width: 50%;
  }

  .explore-category-detail {
    padding: 30px;
    gap: 30px;
  }
}

/* Extra Large Devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1201px) {
 
}
