

.footer-top{
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #000000;
    }
    .footer-top p{
    
        font-size: 30px;
        font-weight: 300;
        line-height: 45px;
        letter-spacing: 0em;
       color: #000000;
       text-align: center;
       margin-bottom: 30px;
        
    }
    
    .footer-top .icons-list{
    display: flex;
    gap: 4%;
    }
    
    
    .footer-top .icons-list .circle{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #000000;
    border-radius: 50%;
    width: 66.82px;
    height: 65.82px;
    }
    
    
    .footer-top .icons-list .circle img{
    width: 32px;
    height: 32px;
    }





    /* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .footer-top {
        height: auto;
        padding: 40px 16px;
      }

      .footer-top p {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 22px;
      }

      .footer-top .icons-list .circle img {
        width: 20px;
        height: 20px;
      }

      .footer-top .icons-list .circle {
      
        width: 45.82px;
        height: 45.82px;
      }

      .footer-top .icons-list {
        display: flex;
        gap: 4%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
  }
  
  /* Small Devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .footer-top {
        height: auto;
        padding: 40px 16px;
      }

      .footer-top p {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 22px;
      }

      .footer-top .icons-list .circle img {
        width: 32px;
        height: 32px;
      }

      .footer-top .icons-list .circle {
        width: 65.82px;
        height: 65.82px;
      }
      .footer-top .icons-list {
        display: flex;
        gap: 8%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
  }
  
  /* Medium Devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .footer-top {
        padding: 0px 30px;
      }
    .footer-top .icons-list {
        display: flex;
        gap: 4%;
        width: 100%;
        justify-content: center;
      }
    

  }
  
  /* Large Devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    .footer-top .icons-list {
        display: flex;
        gap: 4%;
        width: 100%;
        justify-content: center;
      }
  }
  
  /* Extra Large Devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1201px) {
    .footer-top .icons-list {
        display: flex;
        gap: 2%;
        width: 100%;
        justify-content: center;
      }
  }
  