.home-banner {
  position: relative;
  width: 100%;
  height: 120vh;
  /* background-color: aquamarine; */
  background: url('../assets/images/home-bg.jpeg') center/cover no-repeat;
  background-size: 100% auto; /* Set the width to 100% and maintain the aspect ratio */
  background-position: center; /* Center the background image */

}

.home-mid-text {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 6%;
  transform: translate(0%, -50%);
}

.home-mid-text .box1 {
  position: relative;
}

.home-mid-text .box1 .main-heading-text {
  color: #556fe2;
  font-size: 80px;
  font-weight: 500;
  line-height: 120px;
  letter-spacing: 0em;
  text-align: left;
}

.home-mid-text .box1 .vector {
  position: absolute;
  top: 14px;
  right: 281px;
  width: 40px;
  height: 40px;
}

.home-mid-text .box2 .middle-text {
  font-size: 30px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
}

.home-mid-text .box3 {
  display: flex;
  width: 73%;
  justify-content: space-between;
  gap: 5%;
  margin-top: 20px;
}

.home-mid-text .box3 .create-account {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0em;
  padding: 10px;
  width: 50%;
  background-color: #627ff7;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* .home-mid-text .box3 .search-label {
  position: relative;
  width: 50%;
}

.home-mid-text .box3 .search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.home-mid-text .box3 .search-input {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0em;
  padding: 18px 10px 18px 35px; 
  width: 100%;
  color: #000000; 
  border: 1px solid #000000; 
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  margin-left: 10px; 
}

.home-mid-text .box3 .search-input::placeholder {
  color: #000000;
  font-family: 'Poppins', sans-serif;
  margin: 0px auto; 
}

.home-mid-text .box3 .search-input:focus {
  outline: none; 
  border-color: #3498db; 
} */


/* .home-mid-text .box3 .search-button {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0em;
  padding: 10px;
  width: 50%;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.home-mid-text .box3 .search-button svg {
  margin-right: 5px;
} */
.home-mid-text .box3 .search-label {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
}

.home-mid-text .box3 .search-icon-container {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add some spacing between icon and input */
}

.home-mid-text .box3 .search-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.home-mid-text .box3 .search-input {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0em;
  font-family: 'Poppins', sans-serif;
  padding: 18px 10px;
  width: 100%;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
}

.home-mid-text .box3 .search-input::placeholder {
  color: #000000;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 17px;
}

.home-mid-text .box3 .search-input:focus {
  outline: none;
  border-color: #3498db;
}




.home-end-text {
  width: 100%;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.home-end-text p {
  font-size: 30px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #0b0b0b;
}

.home-end-text span {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #556fe2;
}

/* Home Section 2 */

.home-section2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000000;
}
.home-section2 .row {
  display: flex;
}

.home-section2 .row .box {
  width: 25%;
  height: 520px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  border-radius: 8%;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.home-section2 .row .box img {
  width: 100px;
  margin-bottom: 40px;
}
.home-section2 .row .box h6 {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  margin-bottom: 17px;
}

.home-section2 .row .box p {
  font-size: 14px;
  font-weight: 200;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.home-section2 .row .large-box {
  width: 50%;
  height: 520px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #000000;
  border-radius: 8% 0 0 8%;
}

.home-section2 .row .large-box p {
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
}






.home-section3{
width: 100%;
height: 80vh;
display: flex;
flex-direction: column;
background-color: #ffffff;
color: #ffffff;
}

.home-section3 .row1{
height: 70%;
margin-bottom: 2px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #556FE2;
padding: 5px 150px;
}



.home-section3 .row1 .box-heading{

    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    letter-spacing: 0em;

    
}


.home-section3 .row1 .para{
    
    font-size: 30px;
    font-weight: 275;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    
}


.home-section3 .row2{
display: flex;
gap: 2px;
height: 40%;
}

.home-section3 .row2 .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33%;
   border: 1px solid #556FE2;
   background-color: #556FE2;
   padding: 5px 70px;

}



.home-section3 .row2 .box .sub-head{
 
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
   margin-bottom: 10px;
    
}


.home-section3 .row2 .box .sub-para{

    font-size: 18px;
    font-weight: 275;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    
}









.home-section4{
width: 100%;
height: 463px;
display: flex;
}

.home-section4 .right-box{
width: 30%;

}


.home-section4 .right-box img{
    width: 100%;
}


.home-section4 .left-box{
    width: 70%;
    
    display: flex;
    flex-direction: column;
padding: 0% 6%;
    justify-content: center;
}


.home-section4 .left-box p{
color: #0B0B0B;
font-size: 30px;
font-weight: 300;
line-height: 45px;
letter-spacing: 0em;
margin-bottom: 25px;

}

.home-section4 .left-box button{
font-size: 20px;
font-weight: 300;
letter-spacing: 0em;
padding: 10px;
width: 25%;
background-color: #627ff7;
color: #ffffff;
border: none;
border-radius: 10px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;

}













/* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home-banner {
    padding: 20px;
    height: 100vh;
  }
  .home-mid-text {
    width: 100%;
    left: 0%;
    padding: 0px 20px;
    top: 35%;
  transform: translate(0%, -35%);

  }
  .home-mid-text .box1 .main-heading-text {
    font-size: 56px;
  }

  .home-mid-text .box1{
    left: -16px;
  }

  .home-mid-text .box2 .middle-text {
    font-size: 23px;
    line-height: 30px;
  }
  .home-mid-text .box3 {
    width: 100%;
    gap: 5%;
    margin-top: 40px;
    justify-content: center;
  }

  .home-mid-text .box3 .create-account {
    font-size: 17px;
    padding: 10px;
    width: 50%;
    line-height: 21px;
  }

  .home-mid-text .box3 .search-label {
    width: 50%;
  }


  .home-mid-text .box3 .search-input {
   
    padding: 18px 10px 18px 32px;
    width: 100%;
  }

  .home-mid-text .box3 .search-input::placeholder {
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-size: 17px; /* Change the placeholder text color */
  }


  .home-end-text {

    padding: 0px 20px;
  }
  .home-end-text p {
    font-size: 24px;
    line-height: 30px;
  }


  .home-section2{
    background-color: #ffffff;
  }
  .home-section2 .row .box {
    width: 90%;
  }


  .home-section2 .row {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }

  .home-section2 .row .box {
    width: 90%;
    border-radius: 30px !important;
  }
  .home-section2 .row .large-box {
    width: 90%;
    border-radius: 30px !important;
  }

  .home-section3 {
    height: auto;
  }
  .home-section3 .row1 {
width: 100%;
  }


  .home-section3 .row1 {
    width: 100%;
    height: auto;
    padding: 13px 30px;
  }


  .home-section3 .row1 .box-heading {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
  }

  .home-section3 .row1 .para {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
  }


  .home-section3 .row2 {
    gap: 2px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-section3 .row2 .box {
  
    width: 95%;
    padding: 26px;
    border-radius: 20px !important;
  }


  .home-section4 {
    height: auto;
    padding: 19px 9px;
  }


  .home-section4 .left-box p {
   
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
  }
  .home-section4 .right-box {
    width: 50%;
  }

  .home-section4 .left-box button {
    font-size: 18px;
    width: 100%;
  }
  .home-mid-text .box1{
    left: -16px;
  }
}

/* Small Devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .home-banner {
    padding: 40px;
    height: 100vh;
  }
  .home-mid-text {
    width: 100%;
    left: 0%;
    padding: 0px 20px;
    top: 35%;
  transform: translate(0%, -35%);

  }
  .home-mid-text .box1 .main-heading-text {
    font-size: 60px;
  }
  .home-mid-text .box2 .middle-text {
    font-size: 28px;
    line-height: 38px;
  }
  .home-mid-text .box3 {
    width: 100%;
    gap: 5%;
    margin-top: 40px;
    justify-content: center;
  }

  .home-mid-text .box3 .create-account {
    font-size: 17px;
    padding: 10px;
    width: 50%;
    line-height: 21px;
  }

  .home-mid-text .box3 .search-label {
    width: 50%;
  }


  .home-mid-text .box3 .search-input {
   
    padding: 18px 10px 18px 32px;
    width: 100%;
  }

  .home-mid-text .box3 .search-input::placeholder {
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-size: 17px; /* Change the placeholder text color */
  }


  .home-end-text {

    padding: 0px 20px;
  }
  .home-end-text p {
    font-size: 30px;
    line-height: 32px;
  }


  .home-section2{
    background-color: #ffffff;
  }



  .home-section2 .row {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    flex-wrap: wrap;
  }

  .home-section2 .row .box {
    width: 45%;
    border-radius: 30px !important;
  }
  .home-section2 .row .large-box {
    width: 90%;
    border-radius: 30px !important;
  }

  .home-section3 {
    height: auto;
  }


  .home-section3 .row1 {
    width: 100%;
    height: auto;
    padding: 36px;
    margin-bottom: 10px;
  }


  .home-section3 .row1 .box-heading {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
  }

  .home-section3 .row1 .para {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }


  .home-section3 .row2 {
    gap: 2px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-section3 .row2 .box {
  
    width: 95%;
    padding: 26px;
    border-radius: 20px !important;
    margin-bottom: 10px;
  }


  .home-section4 {
    height: auto;
    padding: 19px 9px;
  }


  .home-section4 .left-box p {
   
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
  }
  .home-section4 .right-box {
    width: 50%;
  }

  .home-section4 .left-box button {
    font-size: 18px;
    width: 100%;
  }
}

/* Medium Devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .home-banner {
    padding: 40px;
    height: 100vh;
  }
  .home-mid-text {
    width: 100%;
    left: 0%;
    padding: 0px 20px;
    top: 35%;
  transform: translate(0%, -35%);

  }
  .home-mid-text .box1 .main-heading-text {
    font-size: 70px;
  }
  .home-mid-text .box2 .middle-text {
    font-size: 28px;
    line-height: 38px;
  }
  .home-mid-text .box3 {
    width: 100%;
    gap: 5%;
    margin-top: 40px;
    justify-content: center;
  }

  .home-mid-text .box3 .create-account {
    font-size: 17px;
    padding: 20px 10px;
    width: 40%;
    line-height: 21px;
  }

  .home-mid-text .box3 .search-label{
width: 40%;
  }

  .home-mid-text .box3 .search-input {
   
    padding: 20px 10px 20px 0px;
    width: 100%;
  }

  .home-mid-text .box3 .search-input::placeholder {
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-size: 17px; /* Change the placeholder text color */
  }


  .home-end-text {

    padding: 0px 20px;
  }
  .home-end-text p {
    font-size: 30px;
    line-height: 32px;
  }


  .home-section2{
    background-color: #ffffff;
  }



  .home-section2 .row {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    flex-wrap: wrap;
  }

  .home-section2 .row .box {
    width: 45%;
    border-radius: 30px !important;
  }
  .home-section2 .row .large-box {
    width: 90%;
    border-radius: 30px !important;
  }

  .home-section3 {
    height: auto;
  }


  .home-section3 .row1 {
    width: 100%;
    height: auto;
    padding: 36px;
    margin-bottom: 10px;
  }


  .home-section3 .row1 .box-heading {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
  }

  .home-section3 .row1 .para {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }


  .home-section3 .row2 {
    gap: 2px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-section3 .row2 .box {
  
    width: 95%;
    padding: 26px;
    border-radius: 20px !important;
    margin-bottom: 10px;
  }


  .home-section4 {
    height: auto;
    padding: 19px 9px;
  }


  .home-section4 .left-box p {
   
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
  }
  .home-section4 .right-box {
    width: 50%;
  }

  .home-section4 .left-box button {
    font-size: 18px;
    width: 100%;
  }
}

/* Large Devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .home-banner {
    padding: 40px;
    height: 100vh;
  }
  .home-mid-text {
    width: 82%;
    padding: 0px 3em;
    top: 40%;
    transform: translate(0%, -35%);

  }
  .home-mid-text .box1 .main-heading-text {
    font-size: 60px;
  }
  .home-mid-text .box2 .middle-text {
    font-size: 28px;
    line-height: 38px;
  }
  .home-mid-text .box3 {
    width: 100%;
    gap: 5%;
    margin-top: 40px;
    justify-content: flex-start;
  }

  .home-mid-text .box3 .create-account {
    font-size: 17px;
    padding: 10px;
    width: 35%;
    line-height: 21px;
  }

  .home-mid-text .box3 .search-label{
    width: 35%;
      }
    
      .home-mid-text .box3 .search-input {
       
        padding: 20px 10px 20px 0px;
        width: 100%;
      }
    
      .home-mid-text .box3 .search-input::placeholder {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-size: 17px; 
        text-align: center;/* Change the placeholder text color */
      }

  .home-end-text {

    padding: 0px 20px;
  }
  .home-end-text p {
    font-size: 30px;
    line-height: 32px;
  }


  .home-section2{
    background-color: #ffffff;
  }



  .home-section2 .row {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    flex-wrap: wrap;
  }

  .home-section2 .row .box {
    width: 45%;
    border-radius: 30px !important;
  }
  .home-section2 .row .large-box {
    width: 90%;
    border-radius: 30px !important;
  }

  .home-section3 {
    height: auto;
  }


  .home-section3 .row1 {
    width: 100%;
    height: auto;
    padding: 36px;
    margin-bottom: 10px;
  }


  .home-section3 .row1 .box-heading {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
  }

  .home-section3 .row1 .para {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }


  .home-section3 .row2 {
    gap: 2px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-section3 .row2 .box {
  
    width: 95%;
    padding: 26px;
    border-radius: 20px !important;
    margin-bottom: 10px;
  }


  .home-section4 {
    height: auto;
    padding: 19px 9px;
  }


  .home-section4 .left-box p {
   
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 25px;
  }
  .home-section4 .right-box {
    width: 50%;
  }

  .home-section4 .left-box button {
    font-size: 18px;
    width: 100%;
  }
}

/* Extra Large Devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1201px) {
  .home-mid-text .box3 .search-input {
  
    padding: 18px 10px 18px 34px;
  
    width: 100%;
  }
}





