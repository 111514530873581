.faq-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 75px 35px 75px;
    /* background-color: aquamarine; */
    background: url('../assets/images/faq.png') center/cover no-repeat; 
}
.faq-container .box1 {
    position: relative;
    margin: 65px 0px 30px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .faq-container .box1 .main-heading-text {
    color: #000000;
    font-size: 50px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    
  }

  .faq-container .box1 .main-heading-text  span{

    color: #556FE2;
  }
  
  .faq-container .box1 .vector {
    position: absolute;
    top: -5px;
    right: -24px;
  width: 40px;
  height: 40px;
  }










  .faq-container .box{
    display: flex;
    flex-direction: column;
    padding: 60px 90px;
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px solid #D3D3D3;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin-bottom: 40px;
  
  }

  
  .faq-container .box .box-heading{
font-size: 30px;
font-weight: 400;
line-height: 45px;
letter-spacing: 0em;
color: #556FE2;
margin-bottom: 10px;

  }

  
  .faq-container .box .box-para{

font-size: 22px;
font-weight: 300;
line-height: 33px;
letter-spacing: 0em;
color: #000000;

  }

  
  .faq-container .box .box-ul{ 
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  
  .faq-container .box .box-li{
    list-style-type: disc;

font-size: 22px;
font-weight: 300;
line-height: 33px;
letter-spacing: 0em;


  }




  /* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .faq-container {
        padding: 20px;
      }
      .faq-container .box1 .main-heading-text {
        line-height: 35px;
        font-size: 26px;
    }
    .faq-container .box1 {
        position: relative;
        margin: 45px 0px 30px 0px;
    }

    .faq-container .box {
        padding: 20px;
    }


    .faq-container .box .box-heading {
        font-size: 24px;
        margin-bottom: 10px;
        line-height: 33px;
    }

    .faq-container .box .box-para {
        font-size: 16px;
        line-height: 23px;
        text-align: left;
      }

      .faq-container .box .box-li {
        font-size: 16px;
        line-height: 23px;
      }
  }
  
  /* Small Devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .faq-container {
        padding: 40px;
      }
      .faq-container .box1 .main-heading-text {
        line-height: 50px;
        font-size: 38px;
    }
    .faq-container .box1 {
        position: relative;
        margin: 45px 0px 30px 0px;
    }

    .faq-container .box {
        padding: 40px;
    }


    .faq-container .box .box-heading {
        font-size: 24px;
        margin-bottom: 10px;
        line-height: 33px;
    }

    .faq-container .box .box-para {
        font-size: 16px;
        line-height: 23px;
        text-align: left;
      }

      .faq-container .box .box-li {
        font-size: 16px;
        line-height: 23px;
      }
  }
  
  /* Medium Devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .faq-container .box1 .main-heading-text {
        line-height: 50px;
        font-size: 43px;
      }
      .faq-container {
        padding: 10px 32px;
      }
      .faq-container .box1 {
        position: relative;
        margin: 45px 0px 30px 0px;
    }
    .faq-container .box {
        padding: 42px 42px;
    }

    .faq-container .box .box-para {
        font-size: 17px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0em;
        color: #000000;
      }

  }
  
  /* Large Devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    /* Your styles for large devices go here */
  }
  
  /* Extra Large Devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1201px) {
    /* Your styles for extra large devices go here */
  }
  