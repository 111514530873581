.hamburgerMenu{
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 44444444;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: -100%;
    overflow: hidden;
    transition: right 1s;
    }
    .hamActive{
    right: 0%;
    }
    .Logo{
        margin-bottom: 50px;
    }
    .Logo img{
        width: 220px;
        
    }
    .Menu{
    width: 100%;
    }
    .Menu ul{
        display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
    }
    .item{
        width: 100%;
    text-align: center;
    border-bottom: 1px solid black;
    padding: 10px 0px;
    }
    
    .BottomHeaderLast{
        padding: 10px 0px;
    }
    .SocialIconsContainer{
        display: flex;
    margin-top: 40px;
    gap: 20px;
    }
    
    .closeBtn{
        position: absolute;
        top: 36px;
        right: 18px;
    }