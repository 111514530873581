.signup-container {
  width: 100%;
  display: flex;
  height: 100vh;
}
.signup-container .left-box {
  width: 60%;
}
.signup-container .left-box .left-img {
  width: 100%;
}
.signup-container .right-box {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 150px 40px 30px;
  /* background-color: #556FE212; */
}

.signup-container .right-box .page-heading {
  width: 90%;
}

.signup-container .right-box .page-heading .style-p {
  font-size: 31px;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  color: #242424;
}

.signup-container .right-box .social-button-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 25px 0px 30px 0px;
}

.signup-container .right-box .social-button-box .btn-social {
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.20000000298023224px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: #616161;
  padding: 10px 30px;
  border-radius: 35px;
}

.signup-container .right-box .social-button-box .btn-social .btn-img {
  width: 18px;
  height: 18px;
}

.signup-container .right-box .signup-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-container .right-box .signup-form .input-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.signup-container .right-box .signup-form .input-row .input-label {
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #757575;
}

.signup-container .right-box .signup-form .input-row .input-field {
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  padding: 5px 0px;
  outline: none;
  border: none;
  border-bottom: 1px solid #bdbdbd;
  transition: border-bottom-color 0.3s ease; /* Add transition for smooth color change */
}

.signup-container .right-box .signup-form .input-row .input-field::placeholder {
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  border: none;
  outline: none;
}

/* Add style for input field when focused */
.signup-container .right-box .signup-form .input-row .input-field:focus {
  border: none;
  border-bottom-color: #445ed5;
  outline: none;
}

.signup-container .right-box .signup-form .checkbox-row {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -20px;
  margin-bottom: 15px;
}

.signup-container .right-box .signup-form .checkbox-row .input-check {
  border: none;
  outline: none;
  background-color: #556fe236;
  color: #445ed5;
}

.signup-container .right-box .signup-form .checkbox-row .input-check-label {
  font-size: 10px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  color: #757575;
}

.signup-container .right-box .signup-form .signup-contributor {
  font-size: 13px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #445ed5;
  padding: 15px;
  width: 90%;
  border-radius: 30px;
}

.signup-container .right-box .signup-form .signup-bakers {
  font-size: 13px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: #838383;
  background-color: #ffffff;
  padding: 15px;
}

.signup-container .right-box .page-end-line {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  color: #424242;
}

.signup-container .right-box .page-end-line .inner-style {
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  color: #556fe2;
}

/* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .signup-container {
    flex-direction: column;
  }
  .signup-container .left-box {
    width: 100%;
    
  }
  .signup-container .right-box {
    width: 100%;
    padding: 35px 10px 40px 10px;
  }
  .signup-container .right-box .page-heading {
    width: 97%;
  }
  .signup-container .right-box .page-heading .style-p {
    font-size: 20px;

    line-height: 24px;
    text-align: left;
  }

  .signup-container .right-box .social-button-box .btn-social {
    font-size: 11px;

    gap: 3px;
    width: 50%;
    padding: 9px 14px;
  }
  .signup-container .right-box .signup-form {
    width: 90%;
  }

  .signup-container .right-box .signup-form .signup-contributor {
    padding: 13px;
  }
}

/* Small Devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .signup-container {
        flex-direction: column;
      }
      .signup-container .left-box {
        width: 100%;
        
      }
      .signup-container .right-box {
        width: 100%;
        padding: 35px 10px 40px 10px;
      }
    
      .signup-container .right-box .page-heading {
        width: 97%;
      }
      .signup-container .right-box .page-heading .style-p {
        font-size: 26px;
    line-height: 31px;
        text-align: left;
      }
    
      .signup-container .right-box .social-button-box .btn-social {
        font-size: 12px;
    
        gap: 5px;
        width: 40%;
       
      }
      .signup-container .right-box .signup-form {
        width: 90%;
      }
    
      .signup-container .right-box .signup-form .signup-contributor {
        padding: 13px;
      }
}

/* Medium Devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .signup-container {
        flex-direction: column;
      }
      .signup-container .left-box {
        width: 100%;
      }
      .signup-container .right-box {
        width: 100%;
        padding: 35px 10px 40px 10px;
      }
      .signup-container .right-box .page-heading {
        width: 97%;
      }
   
      .signup-container .right-box .social-button-box .btn-social {
        width: 40%;       
      }
      .signup-container .right-box .signup-form {
        width: 90%;
      }
    
   
}

/* Large Devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .signup-container {
        flex-direction: column;
      }
      .signup-container .left-box {
        width: 100%;
      }
      .signup-container .right-box {
        width: 100%;
        padding: 35px 10px 40px 10px;
      }
      .signup-container .right-box .page-heading {
        width: 97%;
      }
   
      .signup-container .right-box .social-button-box .btn-social {
        width: 40%;       
      }
      .signup-container .right-box .signup-form {
        width: 90%;
      }
    
}

/* Extra Large Devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1201px) {
    .signup-container {
        flex-direction: row;
      }
      .signup-container .left-box {
        width: 60%;
      }
      .signup-container .right-box {
        width: 40%;
        
      }
      .signup-container .right-box .page-heading {
        width: 90%;
      }
   
      .signup-container .right-box .social-button-box .btn-social {
        width: 50%;       
      }
      .signup-container .right-box .signup-form {
        width: 100%;
      }
    
}
