.main-section-campaign{
    position: relative;
  width: 100%;
  height: 80vh;
  /* background-color: aquamarine; */
  background: url('../assets/images/campaign.png') center/cover no-repeat; 
}

.main-section-campaign .campaign-mid-text {
    position: absolute;
    width: 50%;
    top: 50%;
    right: 170px;
    transform: translate(0%, -50%);
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }
  
  .main-section-campaign .campaign-mid-text .box1 {
    position: relative;
    
  }
  
  .main-section-campaign .campaign-mid-text .box1 .main-heading-text {
    color: #000000;
    font-size: 50px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;

    
  }

  .main-section-campaign .campaign-mid-text .box1 .main-heading-text  span{

    color: #556FE2;
  }
  
  .main-section-campaign .campaign-mid-text .box1 .vector {
    position: absolute;
    top: -5px;
    right: -24px;
  width: 40px;
  height: 40px;
  }


  .campaign-mid-text .box3 {
    display: flex;
    margin-top: 20px;
    width: 90%;
  }
  
  .campaign-mid-text .box3 .search-label {
    position: relative;
    width: 100%;
  }
  
  .campaign-mid-text .box3 .search-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .campaign-mid-text .box3 .search-input {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0em;
    padding: 18px 10px 18px 35px; /* Adjust the padding to accommodate the icon */
    width: 100%;
    color: #000000; /* Change the input text color */
    border: 1px solid #000000; /* Change the border color */
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    margin-left: 10px; /* Add margin between icon and text */
  }
  
  .campaign-mid-text .box3 .search-input::placeholder {
    font-size: 17px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }
  
  .campaign-mid-text .box3 .search-input:focus {
    outline: none; /* Optional: Remove the default focus outline */
    border-color: #3498db; /* Optional: Change the border color on focus */
  }
  
  



  .main-section-campaign .campaign-mid-text .start-compaign-btn{
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0em;
    padding: 15px 10px;
    width: 33%;
    background-color: #627ff7;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  }















/* Extra Small Devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-section-campaign {
    padding: 20px;
  }
  .main-section-campaign .campaign-mid-text {
    width: 100%;
    right: 0px;
    padding: 20px;
  }

  .main-section-campaign .campaign-mid-text .box1 .main-heading-text {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
  .campaign-mid-text .box3 .search-label {
    width: 90%;
  }

  .campaign-mid-text .box3 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .main-section-campaign .campaign-mid-text .start-compaign-btn {

    width: 65%;
  }
}

/* Small Devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .main-section-campaign {
    padding: 30px;
  }
  .main-section-campaign .campaign-mid-text {
   
    width: 70%;
   
    right: 65px;
  }

  .main-section-campaign .campaign-mid-text .box1 .main-heading-text {

    font-size: 45px;

    line-height: 60px;}


    .main-section-campaign .campaign-mid-text .start-compaign-btn {

      width: 45%;

    }

}

/* Medium Devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .main-section-campaign .campaign-mid-text .start-compaign-btn {

    width: 45%;

  }
}

/* Large Devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .main-section-campaign .campaign-mid-text .start-compaign-btn {

    width: 45%;

  }
}

/* Extra Large Devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1201px) {
  /* Your styles for extra large devices go here */
}
