/* Video.css */

.video-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-player {
    max-width: 100%;
    max-height: 100%;
  }
  