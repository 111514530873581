

.header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 35px 70px;
  }
  
 
  /* Logo Styling */
  
  .logo {
    width: 20%;
  }
  /* .header .logo .logo-text{

    font-size: 25px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0em;
    } */
    
  .header .logo .logo-icon{
    width: 283px;
  }
  /* General UL LI Styling  */
  ul {
    display: flex;
    gap: 40px;
    justify-content: center;
  }
  li {
    font-size: 18px;
    color: #0B0B0B;
    font-weight: 300;
    transition: color 1s;
  }
  li:hover {
    color: var(--primary-color);
    cursor: pointer;
  }
  
  /* Menu Styling */
  
  .menu {
    width: 60%;
  }
  
  /* Bottom Header Last Part Styling */
  
  .bottomHeaderLast {
    width: 20%;
    float: right;
  }
  .bottomHeaderLast ul {
    float: right;
    gap: 20px;
  }

  .bottomHeaderLast ul li{
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 300;
  }
  
  /* Line Between Login And Sign Up Button */
  
 
  
  .hamburger {
    display: none !important;
  }
  .hamburger:hover{
    cursor: pointer;
  }
  
  
  
  @media (min-width: 0px) and (max-width: 480px) {
 
    .logo{
      width: 40%;
    }
    .header .logo .logo-icon{
      width: 100%;
    }
    .header {
      padding: 11px 11px 0px 0px;
      justify-content: space-between;
    }
    .menu,
    .bottomHeaderLast {
      display: none;
    }
  
    .hamburger {
      display: block !important;
    }
  
  }
  @media (min-width: 481px) and (max-width: 768px) {

    .logo{
      width: 30%;
    }
    .header .logo .logo-icon{
      width: 100%;
    }
    .header {
      padding: 11px 11px 0px 0px;
      justify-content: space-between;
    }
    .menu,
    .bottomHeaderLast {
      display: none;
    }
  
    .hamburger {
      display: block !important;
    }
    .hamburgerIcon {
      font-size: 25px;
    }

  }
  @media (min-width: 769px) and (max-width: 992px) {
  
  
    .logo {
      width: 30%;
    }
  
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 11px 0px 0px;
    }
    .menu,
    .bottomHeaderLast {
      display: none;
    }
  
    .hamburger {
      display: block !important;
    }
    .hamburgerIcon {
      font-size: 25px;
    }
  
  }
  @media (min-width: 993px) and (max-width: 1300px) {
    
    .header {
      padding: 2.5em 3em;
    }
    /* .logo {
      width: 18%;
    } */
   
    .menu {
      width: 62%;
    }

  }
  