* {
  margin: 0;
  padding: 0%;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
